import { createRouter, createWebHistory } from "vue-router";
import vuex from "./vuex";

export default createRouter({
  routes: [
    {
      name: "HomePage",
      path: "/",
      component: () => import("@pages/HomePage"),
      beforeEnter: function (to) {
        vuex.dispatch("meta/assign", to.name);
        vuex.dispatch("home/sliderImages");
        vuex.dispatch("home/serviceImages");        
        vuex.dispatch("home/sortiment");
      },
    },
    {
      name: "LeasingPage",
      path: "/jobradleasing",
      component: () => import("@pages/LeasingPage"),
      beforeEnter: function (to) {
        vuex.dispatch("meta/assign", to.name);
        vuex.dispatch("home/leasing");
      },
    },
    {
      name: "OffersPage",
      path: "/angebote",
      component: () => import("@pages/OffersPage"),
      beforeEnter: function (to) {
        vuex.dispatch("meta/assign", to.name);
        vuex.dispatch("products/getProductsFromBackend");
      },
    },
    {
      name: "CompanyPage",
      path: "/radhaus",
      component: () => import("@pages/CompanyPage"),
      beforeEnter: function (to) {
        vuex.dispatch("meta/assign", to.name);
        vuex.dispatch("home/companyImage");
        vuex.dispatch("home/partnerImages");
      },
    },
    {
      name: "ContactPage",
      path: "/kontakt",
      component: () => import("@pages/ContactPage"),
      beforeEnter: function (to) {
        vuex.dispatch("meta/assign", to.name);
      },
    },
    {
      name: "ImpressPage",
      path: "/impressum",
      component: () => import("@pages/ImpressPage"),
      beforeEnter: function (to) {
        vuex.dispatch("meta/assign", to.name);
      },
    },
    {
      name: "PrivacyPage",
      path: "/datenschutz",
      component: () => import("@pages/PrivacyPage"),
      beforeEnter: function (to) {
        vuex.dispatch("meta/assign", to.name);
      },
    },
  ],
  history: createWebHistory(),
  linkExactActiveClass: "exact-active-link",
});
