import axios from "axios";

const url =
  process.env.NODE_ENV == "development"
    ? "https://api.radhaus-heine.de/api"
    : "https://api.radhaus-heine.de/api";

const api = axios.create({
  baseURL: url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getMeta(uri) {
    return api.get(uri);
  },
  getPartnerImages(uri) {
    return api.get(uri);
  },
  getServiceImages(uri) {
    return api.get(uri);
  },
  getCompanyImage(uri) {
    return api.get(uri);
  },
  getSliderImages(uri) {
    return api.get(uri);
  },
  getProducts(uri) {
    return api.get(uri);
  },
  getLeasing(uri) {
    return api.get(uri);
  },
  getSortiment(uri) {
    return api.get(uri);
  },

  postMail(uri, data) {
    return api.post(uri, data);
  },
};
