export default {
  install(app) {
    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    app.config.globalProperties.$llms = {
      check: function (accept = 2) {
        const banner = document.querySelector(".banner");

        if (
          parseInt(getCookie("cookie-banner")) === 1 ||
          parseInt(getCookie("cookie-banner")) === 0
        ) {
          banner.style.display = "none";
          return;
        }

        if (accept === 1) {
          setCookie("cookie-banner", 1, 365);
          banner.style.display = "none";
        }

        if (accept === 0) {
          setCookie("cookie-banner", 0, 1);
          banner.style.display = "none";
        }
      },
    };
  },
};
