import { createApp } from "vue";
import llms from "@plugins/cookies";
import App from "./App.vue";

// styles and icons from bootstrap
import "animate.css/animate.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";

// router from vue-router
import vueRouter from "@plugins/vue-router.js";

// store from vuex
import vuex from "@/plugins/vuex.js";

import "./registerServiceWorker";

// application
const app = createApp(App);
app.use(llms);
app.use(vuex);
app.use(vueRouter);
app.mount("#app");
