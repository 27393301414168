<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "~animate.css/animate.min.css";

@font-face {
  font-family: "Koulen";
  src: url("@/assets/fonts/Koulen.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  font-weight: 400;
}

.cp {
  color: #ee1c27b7 !important;
}

.exact-active-link {
  text-shadow: 0.5px 0.5px black;
}
</style>
