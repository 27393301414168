import { createStore } from "vuex";

// stores
import home from "@stores/home/index.js";
import meta from "@stores/meta/index.js";
import products from "@stores/products/index.js";

export default createStore({
  modules: {
    home,
    meta,
    products,
  },
});
