export default (
  pageName,
  path = window.location.href,
  injectDynamicContent = false,
  pageData = null
) => {
  if (!injectDynamicContent) {
    // static pages
    let exist = pageData.filter((x) => x.pageName === pageName);
    if (exist.length > 0) {
      document.title = exist[0].title;

      // remove stale metas
      Array.from(document.querySelectorAll("[meta-leluma-systems]")).map((el) =>
        el.parentNode.removeChild(el)
      );

      exist[0].tags
        .map((tagDef) => {
          let tag = document.createElement("meta");
          let urlHelperVal = false; // will help us search for 'og:url'
          Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, urlHelperVal ? path : tagDef[key]);
            urlHelperVal = tagDef[key] === "og:url";
          });
          tag.setAttribute("data-vue-meta-controlled", "");
          return tag;
        })
        .forEach((tag) => document.head.appendChild(tag));
    }
  } else {
    // dynamic pages (e.g blog post page)
    document.title = pageData.title;

    // remove stale metas
    Array.from(document.querySelectorAll("[meta-leluma-systems]")).map((el) =>
      el.parentNode.removeChild(el)
    );

    pageData.tags
      .map((tagDef) => {
        let tag = document.createElement("meta");
        let urlHelperVal = false; // will help us search for 'og:url'
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, urlHelperVal ? path : tagDef[key]);
          urlHelperVal = tagDef[key] === "og:url";
        });
        tag.setAttribute("meta-leluma-systems", "");
        return tag;
      })
      .forEach((tag) => document.head.appendChild(tag));
  }
};
