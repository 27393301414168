import api from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    companyImage: "",
    sliderImages: [],
    partnerImages: [],
    serviceImages: [],
    leasing: [],
    sortiment: [],
  },
  actions: {
    partnerImages({ commit }) {
      commit("setPartnerImages");
    },
    serviceImages({ commit }) {
      commit("setServiceImages");
    },
    sliderImages({ commit }) {
      commit("setSliderImages");
    },
    companyImage({ commit }) {
      commit("setCompanyImage");
    },
    leasing({ commit }) {
      commit("setLeasing");
    },
    sortiment({ commit }) {
      commit("setSortiment");
    },
  },
  mutations: {
    setPartnerImages(state) {
      if (state.partnerImages.length === 0) {
        api
          .getPartnerImages(`/home/partner/all`)
          .then(({ data }) => {
            state.partnerImages = data;
          })
          .catch((error) => {
            console.log("setPartnerImages", error);
          });
      }
    },
    setServiceImages(state) {
      if (state.serviceImages.length === 0) {
        api
          .getServiceImages(`/home/service/all`)
          .then(({ data }) => {
            state.serviceImages = data;
          })
          .catch((error) => {
            console.log("setServiceImages", error);
          });
      }
    },
    setSliderImages(state) {
      if (state.sliderImages.length === 0) {
        api
          .getSliderImages(`/home/slider/all`)
          .then(({ data }) => {
            state.sliderImages = data;
          })
          .catch((error) => {
            console.log("setSliderImages", error);
          });
      }
    },
    setCompanyImage(state) {
      if (state.companyImage == "") {
        api
          .getCompanyImage(`/home/company/image`)
          .then(({ data }) => {
            state.companyImage = data;
          })
          .catch((error) => {
            console.log("companyImage", error);
          });
      }
    },
    setLeasing(state) {
      if (state.leasing.length == 0) {
        api
          .getLeasing(`/home/leasing`)
          .then(({ data }) => {
            state.leasing = data;
          })
          .catch((error) => {
            console.log("getLeasing", error);
          });
      }
    },
    setSortiment(state) {
      if (state.sortiment.length == 0) {
        api
          .getSortiment(`/home/sortiment`)
          .then(({ data }) => {
            state.sortiment = data;
          })
          .catch((error) => {
            console.log("getSortiment", error);
          });
      }
    },
  },
  getters: {
    getSliderImages(state) {
      return state.sliderImages;
    },
    getCompanyImage(state) {
      return state.companyImage;
    },
    getPartnerImages(state) {
      return state.partnerImages;
    },
    getServiceImages(state) {
      return state.serviceImages;
    },
    getLeasing(state) {
      return state.leasing;
    },
    getSortiment(state) {
      return state.sortiment;
    },
  },
};
