import api from "@/plugins/axios.js";

export default {
  namespaced: true,
  state: {
    products: [],
  },
  actions: {
    getProductsFromBackend({ commit }) {
      commit("setProducts");
    },
  },
  mutations: {
    setProducts(state) {
      if (state.products.length === 0) {
        api
          .getProducts(`/products/all`)
          .then(({ data }) => {
            state.products = data;
          })
          .catch((error) => {
            console.log("setProducts", error);
          });
      }
    },
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
  },
};
