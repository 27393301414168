import assignMetas from "@/plugins/assignMetas.js";
import api from "@/plugins/axios.js";

export default {
  namespaced: true,
  state: {
    meta: {},
  },
  actions: {
    assign({ commit }, payload) {
      commit("setMeta", payload);
    },
  },
  mutations: {
    setMeta(state, payload) {
      if (!state.meta[payload]) {
        api
          .getMeta(`/home/meta?name=${payload}`)
          .then(({ data }) => {
            state.meta[payload] = data;
            assignMetas(payload, window.location.href, true, data);
          })
          .catch(({ message }) => {
            console.log(message);
          });
      } else {
        assignMetas(payload, window.location.href, true, state.meta[payload]);
      }
    },
  },
  getters: {},
};
